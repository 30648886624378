@media screen and(min-width:767px) and (max-width:1199px){
  .wid-label{max-width: 11.33333% !important;}
  .wid-texta{max-width: 88.66667% !important;}
}
.side-by-side {
  width: 90%;
}

.side-by-side li {
  display: inline;
  border: 1px solid #ececec;
  margin: 2px;
  padding: 10px;
}

.ckboxalign{
  vertical-align: middle;
  margin: 0px;
}
.set-padding {
  padding: 10px;
}

.error-icon {

  top: -3px;
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  right: 0;
  transform: translate(40%, -50%);
}

.error-icon-date-range {
  top: 17px;
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  right: -14px;
  transform: translate(-30%, -40%);
}

.error-icon-textarea {
  /* top: 0px !important; */
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  right: -6px;
  transform: translate(-40%, -40%); 
}

.error-icon-no-margin {
  /* top:50%; */
  /* margin-top: 0em; */
  /* left: auto;
  /* right:0.8em; */
  /* color: #f64b4b; */
  /* position: absolute; */
  /* font-size: 1.8em !important; */

  /* margin-top: 0.1em; */
  /* right: 0em; */ 

  top: -3px;
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  right: 0;
  transform: translate(40%, -50%);

}

.search-icon {
  margin-top: 0.35em;
  left: auto;
  right: 1.1em;
  color: #007bff !important;
  position: absolute;
  font-size: 1.3em !important;
}

.error-icon-dropdown {
  /* top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.3em;
  color: #f64b4b;
  position: absolute;
  font-size: 1.8em !important; */

  top: -3px;
  right: 0px !important;
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  transform: translate(40%, -50%);

}

.error-icon-dropdown-no-margin {
  /* top:50%; */
  /* top: -3px; */
  /* margin-top: 0em; */
  /* left: auto; */
  /* right: 1.0em; */
  /* color: #f64b4b; */
  /* position: absolute; */
  /* font-size: 1.8em !important; */
  top: -3px;
  right: 0px;
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  transform: translate(40%, -50%);
}

/* s-lookupalt .error-icon-dropdown {
    right: 2em !important;
    right: 1.5em
} */

.error-icon-dtp {
  /* top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.4em;
  color: #f64b4b;
  position: absolute;
  font-size: 1.8em !important; */
  top: -3px;
  /* right: -23px; */
  color: #f64b4b;
  position: absolute;
  font-size: 1.5em !important;
  transform: translate(-63%, -45%);
}

.tooltip-error.ui-tooltip .ui-tooltip-text {
  background-color: #f64b4b;
  color: #fff;
}

.error-icon-multi {
  top: 16%;
  /* margin-top: -0.5em; */
  left: auto;
  right: 1.3em;
  color: #f64b4b;
  position: absolute;
  font-size: 1.8em !important;
}

/* .error-icon-multi{
    top: 7%;
    margin-top: -0.5em;
    left: auto;
    right: 1.3em;
    color: #f64b4b;
    position: absolute;
    font-size: 1.8em !important;
} */
.tooltip-error.ui-tooltip .ui-tooltip-arrow {
  border-top-color: #f64b4b !important;
  /* border-left-color:#f64b4b !important;
    border-right-color:#f64b4b !important;
    border-bottom-color:#f64b4b !important; */
  color: #fff;
}

/* .info-icon {
  font-size: 1.5em !important;
  color: #007bff;
  float: right;
  margin-right: 10px;
} */

.tooltip-info.ui-tooltip .ui-tooltip-text {
  background-color: #0056b3;
  color: #fff;
}

.tooltip-info.ui-tooltip .ui-tooltip-arrow {
  border-top-color: #0056b3 !important;
  /* border-left-color:#f64b4b !important;
    border-right-color:#f64b4b !important;
    border-bottom-color:#f64b4b !important; */
  color: #fff;
}

.inactive-row {
  background-color: #f3dfdf !important;
}

/* .ui-table .ui-table-tbody > tr {
    background:#ffffff !important;
} */
.ui-table .ui-table-tbody>tr:nth-child(even) {
  background: #ececec !important;
}

.icon-desable {
  color: gray !important;
}

.disabled {
  display: block;
  position: relative;
}

.content-body .disabled::after {
  content: '';
  right: 15px;
  position: absolute;
  width: 0;
  height: 0;
  top: 15px;
  border-width: 10px;
  border-style: solid;
  border-color: #ff0000 #ff0000 transparent transparent;
}

::ng-deep .ui-table table .ui-table table {
  table-layout: auto !important;
}

::ng-deep .ui-table-tablewrapper {
  width: auto !important;
}

::ng-deep .ui-table-resizable {
  padding-bottom: 1px;
  width: auto !important;
}

.ui-table .ui-table-thead>tr>th {
  /* height: 40px !important; */
  height: 35px !important;
}

.ui-table .ui-table-tbody>tr,
.ui-table .ui-table-thead>tr>th,
.ui-table .ui-table-tfoot>tr>td {
  min-width: 100%;
  /* padding: 0px !important; */
  padding-left: 4px !important;
  color: black;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  border-width: 1px;

}

.ui-tabview .ui-tabview-panels {
  background-color: #f8f9fa !important;
}

.ui-tabview .ui-tabview-panel {
  background-color: #f8f9fa !important;
}

.selected-row {
  background-color: #e7e9e9;
}

.selected-qual {
  background-color: #c5e1ff;
}

.grid-selected-row {
  background-color: #c9dcfa !important;
  /* background-color:#007bff !important; */
  /* color: #000; */
}

.holiday-row {
  background-color: #f8dac1 !important;
  font-weight: bold;
  /* background-color:#007bff !important; */
  /* color: #000; */
}

.badge-info {
  border-color: #007bff !important;
  background-color: #007bff;
  /* float: right; */
  border-radius: 10em;
  padding: 0.2em 0.5em 0.2em 0.5em !important;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  transition: color .15s;
  margin-left: 10px;
  font-size: 13px;
}

.remove-icon {
  position: absolute;
  /* margin-top: -25px; */
  font-size: 1.5em !important;
  color: #f64b4b;
  top: -2px;
  right: -2px;
}

.autocomplete-btn {
  margin-left: -2.4em !important;
  margin-top: 0.12em !important;
  position: absolute !important;
  width: 30px !important;
  border-radius: 50px !important;
  /* height: 30px !important; */
  background-color: #fff !important;
  border: 0px !important;
}

.autocomplete-btn span {
  color: #73a0cf;
  font-size: 18px;
}

.autocomplete-btn span.ui-button-text {
  padding: 0 !important
}

.autocomplete-menu-btn {
  margin-left: 29.1em !important;
  margin-top: 0.12em !important;
  position: absolute !important;
  width: 30px !important;
  border-radius: 50px !important;
  /* height: 30px !important; */
  background-color: #fff !important;
  border: 0px !important;
  z-index: 1;
}

.autocomplete-menu-btn span {
  color: #73a0cf;
  font-size: 18px;
}

.autocomplete-menu-btn span.ui-button-text {
  padding: 0 !important
}

p-autocomplete span .ui-inputtext {
  height: 28px;
}

.ui-table .ui-table-thead>tr>th:first-child,
.ui-table .ui-table-tbody>tr>td:first-child {
  border-left: none;
}

.ui-table .ui-table-thead>tr>th:last-child,
.ui-table .ui-table-tbody>tr>td:last-child {
  border-right: none;
}

.ui-paginator {
  border-left: 0 !important;
  border-right: 0 !important
}

.datepicker-directive .ui-datepicker-trigger.ui-calendar-button .ui-button-text,
.datepicker-directive .ui-button-text-empty .ui-button-text {
  padding: 0.25em .25em 0.25em .25em !important;
}

body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 15% !important;
}

.ui-dropdown {
  min-width: 100%;
}

.ui-table p-sorticon {
  float: right;
}

.ui-table p-sorticon>.ui-sortable-column-icon {
  margin-top: 3px;
  font-size: 14px;
}

.ui-paginator .ui-inputtext {
  background: transparent !important;
}

.ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
  top: 55%;
  font-size: 14px;
}

s-dropdown .ui-inputtext {
  padding: 0.200em !important;
}

.ui-datepicker .ui-datepicker-prev {
  left: -0.875em !important;
}

.ui-datepicker .ui-datepicker-next {
  right: -0.875em !important;
}


body .ui-button.ui-button-text-icon-left .ui-button-text {
  padding: .25em 1em 0.35em 2.1em !important;
}

.ui-datepicker-title {
  display: flex !important;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  top: 70% !important;
}

.ui-fileupload-choose {
  margin-bottom: -4px !important;
}

.ui-multiselect-trigger-icon {
  font-size: 14px !important;
}

body .ui-multiselect .ui-multiselect-label {
  padding: .25em 2em .25em .25em;
}

body .ui-toast .ui-toast-message.ui-toast-message-warn {
  border-radius: 10px;
  background: #faedc4;
  border-left: 6px #816204 solid; 
  color: #5e4803;
}
 body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-message-content .ui-toast-close-icon.pi-times {
  color: #5e4803 !important;
} 

body .ui-toast .ui-toast-message.ui-toast-message-error {
  border-radius: 10px;
  background: #fad3e7;
  border-left: 6px #822854 solid; 
  color: #5e1d3d;
}
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-message-content .ui-toast-close-icon.pi-times {
  color: #5e1d3d !important;
}

body .ui-toast .ui-toast-message.ui-toast-message-success {
  border-radius: 10px;
  background: #caf1d8;
  border-left: 6px #136c34 solid; 
  color: #0e4f26;
}
body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-message-content .ui-toast-close-icon.pi-times {
  color: #0e4f26 !important;
}

body .ui-toast .ui-toast-message.ui-toast-message-info {
  border-radius: 10px;
  background: #d0e1fd;
  border-left: 6px #204887 solid; 
  color: #183462;
}
body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-message-content .ui-toast-close-icon.pi-times {
  color: #183462 !important;
}

/* p-paginator
ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted */

.ui-paginator {
  text-align: right !important;
}

.ui-paginator span {
  text-align: left !important;
}


/* color Change */


body .ui-paginator {
  background-color: #73a0cf;
}

body .ui-paginator .ui-dropdown {

  background-color: #3c73ad !important;
  border-radius: 0;

}

body .ui-dropdown .ui-dropdown-trigger {
  background-color: transparent;
}

body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: #ffffff;
  background-color: #3c73ad;
}

body .ui-paginator .ui-dropdown .ui-dropdown-trigger,
body .ui-paginator .ui-dropdown .ui-dropdown-label {
  color: #ffffff;
}

body .ui-table .ui-sortable-column:not(.ui-state-highlight) {

  color: #ffffff;
  background: #73a0cf;
  /* linear-gradient(360deg, #3174ff, #8fb8ff); */
}


body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #ffffff;
}


.ui-paginator .ui-paginator-left-content {
  color: #fff;
}

.action {
  /* background: linear-gradient(360deg, #3174ff, #8fb8ff) !important;
  color: #fff !important; */
  color: #ffffff !important;
  background: #73a0cf !important;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: #fff;
}

.ui-multiselect-filter-container {
  width: 98% !important;
}

.ui-multiselect .ui-multiselect-label {
  font-weight: normal !important;
}

s-multiselect .error-icon-dropdown {
  right: 0.7em !important;
}


@media (max-width:640px) {
  .ui-table table {
    table-layout: unset !important;
  }
}


/* Multiselect Dropdown */

.ui-multiselect-header {
  display: flex;
  align-items: center;
}

.ui-multiselect-filter-container {
  width: 88% !important;

}

.ui-multiselect-filter-container .ui-inputtext {
  height: 28px;
}

.even-row {
  background-color: #ececec;
}

.odd-row {
  background-color: #ffffff;
}


/* ------------Side Bar Menu------------ */

.menu-bar-scroll {

  height: calc(100vh - 95px)
}

.menu-bar-content .ui-scrollpanel-bar-x,
.menu-bar-scroll .ui-scrollpanel-bar-x,
.popover-scroll .ui-scrollpanel-bar-x,
.container-scrollbar .ui-scrollpanel-bar-x {
  display: none
}

.popover.popover-content.bs-popover-top-left,
.popover.popover-content.bs-popover-bottom-left {
  transform: translate(2rem, -0.8rem) !important;
}


.popover.popover-content.bs-popover-top-right,
.popover.popover-content.bs-popover-bottom-right {
  transform: translate(-2rem, -0.8rem) !important;
}

.popover.popover-content.bs-popover-bottom .arrow,
.popover.popover-content.bs-popover-bottom-right .arrow,
.popover.popover-content.bs-popover-bottom-left .arrow {
  top: calc((0.99rem + 0rem) * -1) !important;
}

.popover.popover-content.bs-popover-bottom .arrow::before,
.popover.popover-content.bs-popover-bottom .arrow::after,
.popover.popover-content.bs-popover-bottom-right .arrow::before,
.popover.popover-content.bs-popover-bottom-right .arrow::after,
.popover.popover-content.bs-popover-bottom-left .arrow::before,
.popover.popover-content.bs-popover-bottom-left .arrow::after {
  border-width: 0px 0.99rem 0.85rem 0.99rem !important;
}

.popover.popover-content.bs-popover-top,
.popover.popover-content.bs-popover-top-right,
.popover.popover-content.bs-popover-top-left {
  margin-top: -0.25rem !important;
}

.popover.popover-content.bs-popover-top .arrow,
.popover.popover-content.bs-popover-top-right .arrow,
.popover.popover-content.bs-popover-top-left .arrow {
  bottom: calc((0.99rem + 0rem) * -1) !important;
}

.popover.popover-content.bs-popover-top .arrow::before,
.popover.popover-content.bs-popover-top .arrow::after,
.popover.popover-content.bs-popover-top-right .arrow::before,
.popover.popover-content.bs-popover-top-right .arrow::after,
.popover.popover-content.bs-popover-top-left .arrow::before,
.popover.popover-content.bs-popover-top-left .arrow::after {
  border-width: 0.99rem 0.85rem 0rem !important;
}


.menu-bar-scroll .ui-scrollpanel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-bar-content {
  height: calc(100vh - 130px)
}

.az-iconbar-primary .nav-link {
  padding: 15px 20px;
}

.az-iconbar .nav-link+.nav-link {
  padding: 15px 20px;
}

.az-iconbar-primary .nav-link.active {

  background-color: #0176bd;
}

.scrollbar-color .ui-scrollpanel-bar {

  background-color: #0176bd;

}

.ui-scrollpanel-bar {
  border-radius: 10px;
  opacity: 1;
  transition: background-color .3s;

}

label {
  position: relative;
}


@media (min-width:992px) {
    .body-text {
      background-color: #f8f9fa;
      border-right: 1px solid #c3c3c3;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      height: calc(100% - 0px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .std-prom-tab {
      height: calc(100vh - 128px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px;
    }
  }

.dailog-drogdrop {
  height: calc(100vh - 100px);
  background: #fff;
}


.reportbuilder-dailogbox .panel-body {
  height: calc(100vh - 277px);
  overflow: auto;
  margin-top: 20px;
}

.reportbuilder-dailogbox {
  height: calc(100vh - 215px);
}

.picklist .ui-picklist {
  height: calc(100vh - 297px);
}

.picklist .ui-picklist .ui-picklist-list {
  height: calc(100vh - 410px);
}

@media (min-width:768px) {
  .dailog-drogdrop {
    width: 95%;

  }

  .dailog-img-viewer {
    width: 95%;
  }

  .picklist .ui-picklist .ui-picklist-buttons {
    padding: 0.571em 4em;
  }
}

/* Module Popover */

.popover.popover-content.sm {
  width: auto !important;
}

.popover.popover-content {
  padding: 0.5rem !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border: 1px solid #c8c8c8 !important;
  position: absolute !important;
  top: 109px !important;

  
}
/* .popover.popover-content:nth-last-child(8){
  top: 0px !important;
  left:0px !important;
} */
/* @media screen and (max-width:350px){
  .popover.popover-content{
    
    top: 103px !important;
    left:-34px !important;
  }
} */
/* .popover.popover-content .popover-body {
  max-height: calc(100vh - 600px);
  overflow-y: auto;
} */
/* @media screen and (max-width:1645px){
  .module-list-completed  {width: 221px}
} */

.popover-body>ul.popover-dialog-content>li:last-of-type>ul>li:last-of-type>a {
  border-bottom: none !important
}

.popover-dialog-content {
  width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}


.popover.popover-content.bs-popover-bottom .arrow::after,
.popover.popover-content.bs-popover-bottom-right .arrow::after,
.popover.popover-content.bs-popover-bottom-left .arrow::after {

  border-bottom-color: #ffffff !important;
}

.popover.popover-content.bs-popover-top .arrow::after,
.popover.popover-content.bs-popover-top-right .arrow::after,
.popover.popover-content.bs-popover-top-left .arrow::after {

  border-top-color: #ffffff !important;
}

.popover.popover-content.bs-popover-right .arrow::after {
  border-right-color: #ffffff !important;
}

.popover.popover-content.bs-popover-left .arrow::after {

  border-left-color: #ffffff !important;
}


/*  ImageViewer */

s-imageviewer {
  width: 100%
}



.imgviewer-icons {
  font-size: 12px !important;
  padding: 5px;
}

.imgviewer-zoom-controls {
  border: 1px solid #c8c8c8;
  padding: 0px 0px;
  display: inline-block;
  margin: 12px 10px;
  border-radius: 20px;
}

.imgviewer-control {
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}

.zoom-control {

  padding: 0 10px;
}

.imgviewer-rotate-controls {
  border: 1px solid #c8c8c8;
  padding: 0px 0px;
  display: inline-block;
  margin: 8px 10px;
  border-radius: 20px;
}

.rotate-control {

  padding: 0 10px;
}

.imgviewer-screen-controls {
  border: 1px solid #c8c8c8;
  padding: 0px 0px;
  display: inline-block;
  margin: 8px 10px;
  border-radius: 20px;
}

.screen-control {

  padding: 0 10px;
}

.imgviewer-download-controls {
  border: 1px solid #c8c8c8;
  padding: 0px 0px;
  display: inline-block;
  margin: 8px 10px;
  border-radius: 20px;
}

.download-control {
  padding: 0 10px;
}

.icon-left {
  border-left: 1px solid rgba(255, 255, 255, 0.5)
}

.footer-icon {
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.image-gallery-2 {
  background-color: #e2e2e2 !important;
  height: calc(100% - 60px) !important;
}

/* #outerContainer {

  height: calc(100% - 150px) !important;

} */
select.ui-datepicker-year{
  width: 100% !important
}


.ngx-charts-outer {
  width: 100% !important;
}

ngx-charts-legend>div {
  width: 100% !important;
}


.ui-multiselect-label-container {
  height: 28px;
}


.ui-scrollpanel-wrapper {
  z-index: 0 !important;
}

.dot-deleleted {
  /* height: 10px;
  width: 10px;
  background-color: #f64b4b;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer; */
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.dot-pending {
  /* height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer; */
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.dot-inactive {
  /* height: 10px;
  width: 10px;
  background-color: rgb(137, 186, 206);
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer; */
  height: 10px;
  width: 10px;
  background-color: rgb(137, 186, 206);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.dot-active {
  /* height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer; */
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.dot-cancelled {
  /* height: 10px;
  width: 10px;
  background-color: rgb(143, 142, 132);
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer; */
  height: 10px;
  width: 10px;
  background-color: rgb(143, 142, 132);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.dot-othloc {
  height: 10px;
  width: 10px;
  background-color: rgb(168, 147, 28);
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer;
}

.dotbtn-content {
  display: inline-block;
  position: relative;
}

.drop-list-dots {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px 5px 5px;
  right: 5px;
  top: 43px;
  padding: 10px;
}

.drop-list-dots::before {
  content: "";
  border: 1px solid #e4e4e4;
  width: 15px;
  height: 15px;
  z-index: -1;
  position: absolute;
  right: 10px;
  top: -9px;
  background: #cecece;
  transform: rotate(45deg);
}

.drop-list-dots::after {
  content: "";
  background: white;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  position: absolute;
  transform: rotate(45deg);
  right: 10px;
  top: -8px;
  z-index: 0;
}

.drop-list-dots a {
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.drop-list-dots a:hover {
  background-color: #e2e2e2;
  border-radius: 5px;
}

.dotbtn-content:hover .drop-list-dots {
  display: block;
}

@media (max-width:767.98px) {
  .drop-list-dots {
    right: -85px;
  }

  .drop-list-dots::before,
  .drop-list-dots::after {
    left: 10px
  }
}

.dot-btn-clr {
  display: flex;
  width: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.p-1 {
  padding: 0.25rem !important;
}

/* .form-control.p-1 {
  padding-right: 1.40rem !important;
} */

.pl-30 {
  padding-left: 30px !important
}

.table-colunm-left table>tbody>tr>td:first-child {
  text-align: left !important;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border-color: #007ad9 !important;
}

.mondatory_border .ui-dropdown {

  border-color: rgb(255, 166, 166)
}

/* .datepicker-directive {
  position: relative;
} */

.bg-gray-100 {
  background-color: #ffffff;
}

p-multiselectitem .ui-multiselect-item .ui-chkbox {
  float: left !important;
}

.grid-col .row {
  justify-content: flex-end !important;
}

.grid-col-right {
  text-align: right !important;
}
.grid-col-left {
  text-align: left !important;
}

.char-failure{
   color: #ff0000;
   margin: 5px;
  font-size: 0.85rem;
  }

.char-success{
color: #4ea755;
margin: 5px;
font-size: 0.85rem;
}

.large-input {
  font-size: 15pt !important;
  font-weight: bold;
}

.sideMeneNBLE{
  display: block !important;
}

.sideMenuDisable{
  display: none !important;
}

.module-lists .module-li .popover-content.popover .popover-body{
  min-height: 50px !important;
  max-height: 300px !important;
  overflow-y: auto !important;
}
.popover-main-ul>.popover-main-li>.popover-main-link>.popover-main-title>.popover-main-text~i{
  padding-right: 10px !important;
}
.show{
  background: 0 0;
  border-bottom: 4px solid #007bff;
  position: relative;
  color: #007bff
}

.ui-scrollpanel-content{
  width: 100%;
  padding: 0px;
  overflow-x: overlay;
}
@media (max-width:1200px) {
  .res-pad {
    padding-top: 10px;
  }
}

/* @media (max-width:991px) and (min-width:944px) {
  .pad-mea-que {
    margin-left: 10px !important;
  }
}

@media (max-width:575px) and (min-width:448px) {
  .pad-mea-que {
    margin-left: 10px !important;
  }
}

/* @media (min-width: 944px) and (min-width:922px) {
  .pad-mea-que     {
    margin-left: auto !important;
  }
} */

/* @media (max-width:767px) and (min-width:645px) {
  .pad-mea-que {
    margin-left: 10px !important;
  }
}  */




/* .messenger-chat-receive,
.messenger-chat-sent {
  
} */
.messenger-chat-receive{
  text-align: left;
}
.messenger-chat-sent{
  text-align:right;
}

.messenger-sent-box,
.messenger-received-box {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  /* width: 100%; */
  border-radius: 5px;
  font-size: 15px;
  position: relative;
}

.messenger-received-box {
  background: #cfe6ff;
  border: 1px solid #cfe6ff;
  box-shadow: 0px 2px 2px #a3c9f3;
}

.messenger-sent-box {
  background: #ffeaad;
  margin-left: auto;
  border: 1px solid #ffefc0;
  box-shadow: 0px 2px 2px #fbdc81;
}

.footer-messenger-sent {
  position: absolute;
  color: #cfe6ff;
  top:16px;
  right: 23px;
  background: #73a0cf;
  width: 45px;
  height: 45px;
  font-size: 25px;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  cursor: pointer;
  transition: all .5s ease-in-out
}

.footer-messenger-sent:hover {
  background: #cfe6ff;
  color:#73a0cf
}

.msgtext{
  height: 55px !important;
  border-radius: 10px;
  resize: none;
}

.footer-section {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
}

.footer-messenger-sent-2 {
  position: absolute;
  color: #cfe6ff;
  top:10px;
  right: 5px;
  background: #73a0cf;
  width: 45px;
  height: 45px;
  font-size: 25px;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  cursor: pointer;
  transition: all .5s ease-in-out
}

.footer-messenger-sent-2:hover {
  background: #cfe6ff;
  color:#73a0cf

}

/* Bhaskar */

.wd-xl-100p{height: 100%;}

.error-icon-label {
    top: 4px;
    color: #f64b4b;
    position: absolute;
    font-size: 1.5em !important;
}

.rBorder {
  border-right: 1px solid silver !important;
  padding-right: 14px !important;
  max-height: calc(100vh - 95px) !important;
  overflow-y:scroll !important;
}

body .ui-sidebar{
        padding: 5px !important;
    }

    body .ui-sidebar .content-body {
      height: calc(100vh - 70px);
  }

  body .ui-sidebar .column-sm {
    height: calc(100vh - 72px);
}